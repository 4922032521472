import { inject, Aurelia, PLATFORM } from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import Echo                          from 'laravel-echo';
import io                            from 'socket.io-client';
import environment                   from 'environment';

@inject(Aurelia, AppContainer)
export class LaravelEchoService {

    instantiated = false;

    /**
     * Constructor
     *
     * @param aurelia
     * @param appContainer
     */
    constructor(aurelia, appContainer) {
        this.aurelia      = aurelia;
        this.appContainer = appContainer;
    }

    /**
     * Instantiates laravel echo
     */
    instantiate() {
        if (environment.webSockets.supported) {
            window.Echo = new Echo({
                client:      io,
                broadcaster: environment.webSockets.broadcaster.name,
                host:        environment.webSockets.broadcaster.endpoint,
                key:         environment.webSockets.broadcaster.apiKey,
                namespace:   environment.webSockets.broadcaster.namespace,
                auth:        {
                    headers: {
                        'Authorization': 'Bearer ' + this.appContainer.localStorage.authToken(),
                    },
                },
            });

            this.instantiated = true;

            this.subscribeMaintenanceModeEvents();
        }
    }

    /**
     * Subscribes maintenance mode events
     */
    subscribeMaintenanceModeEvents() {
        window.Echo.channel('App.GlobalEvents')
            .listen('MaintenanceModeInitiated', () => this.aurelia.setRoot(PLATFORM.moduleName('maintenance')))
            .listen('MaintenanceModeConcluded', () => window.location.reload(true));
    }

}
