import { inject }          from 'aurelia-framework';
import { UsersRepository } from 'modules/administration/users/services/repository';
import { BooleanOption }   from 'modules/administration/models/boolean-option';
import { User }            from 'modules/administration/models/user';
import { EntityType }      from 'modules/management/models/entity-type';

@inject(UsersRepository)
export class AuthenticatedUser {

    /**
     * Constructor
     *
     * @param usersRepository
     */
    constructor(usersRepository) {
        this.usersRepository = usersRepository;
    }

    /**
     * Fetches the logged user from server
     *
     * @returns {*}
     */
    fetchAuthenticatedUser() {
        return this.usersRepository.authenticatedUser().then((data) => {
            this.user = data;
        });
    }

    /**
     * Check whether the user is root
     *
     * @returns {boolean}
     */
    isRoot() {
        return this.user.id === User.ROOT_USER_ID;
    }

    /**
     * Check whether the user is a system reserved user
     *
     * @returns {boolean}
     */
    isSystemReserved() {
        return this.user.system_reserved === BooleanOption.YES;
    }

    /**
     * Checks whether the user belongs to a managing entity
     *
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToManagingEntity(allowGlobalAdministrator = true) {
        return this.belongsToEntityType(EntityType.MANAGING_ENTITY, allowGlobalAdministrator);
    }

    /**
     * Checks whether the user belongs to a controlling entity
     *
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToControllingEntity(allowGlobalAdministrator = true) {
        return this.belongsToEntityType(EntityType.CONTROLLING_ENTITY, allowGlobalAdministrator);
    }

    /**
     * Checks whether the user belongs to an inspecting entity
     *
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToInspectingEntity(allowGlobalAdministrator = true) {
        return this.belongsToEntityType(EntityType.INSPECTING_ENTITY, allowGlobalAdministrator);
    }

    /**
     * Checks whether the user belongs to a maintenance company
     *
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToMaintenanceCompany(allowGlobalAdministrator = true) {
        return this.belongsToEntityType(EntityType.MAINTENANCE_COMPANY, allowGlobalAdministrator);
    }

    /**
     * Checks whether the user belongs to an installing company
     *
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToInstallingCompany(allowGlobalAdministrator = true) {
        return this.belongsToEntityType(EntityType.INSTALLING_COMPANY, allowGlobalAdministrator);
    }

    /**
     * Checks whether the user is/belongs to an owner
     *
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToOwner(allowGlobalAdministrator = true) {
        return this.belongsToEntityType(EntityType.OWNER, allowGlobalAdministrator);
    }

    /**
     * Check if the user is/belongs to a given entity type.
     *
     * @param entityTypeId
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToEntityType(entityTypeId, allowGlobalAdministrator = true) {
        if (allowGlobalAdministrator === true && this.is('global-administrator')) {
            return true;
        }

        return !isEmpty(this.user.entity) && this.user.entity.entity_type_id === entityTypeId;
    }

    /**
     * Check if the user is/belongs to a given entity.
     *
     * @param entityTypeId
     * @param allowGlobalAdministrator
     *
     * @returns {boolean}
     */
    belongsToEntity(entityId, allowGlobalAdministrator = true) {
        if (allowGlobalAdministrator === true && this.is('global-administrator')) {
            return true;
        }

        return !isEmpty(this.user.entity) && this.user.entity.id === Number(entityId);
    }

    /**
     * Check if the user has a role or roles.
     *
     * @param role
     * @param all
     *
     * @returns {boolean}
     */
    is(role, all = false) {
        let roles = this.getArrayFrom(role);

        return all ? this.isAll(roles) : this.isOne(roles);
    }

    /**
     * Check if the user has at least one role.
     *
     * @param roles
     *
     * @returns {boolean}
     */
    isOne(roles) {
        let length = roles.length;

        for (let i = 0; i < length; i++) {
            if (this.hasRole(roles[i])) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if the user has all roles.
     *
     * @param roles
     *
     * @returns {boolean}
     */
    isAll(roles) {
        let length = roles.length;

        for (let i = 0; i < length; i++) {
            if (!this.hasRole(roles[i])) {
                return false;
            }
        }

        return true;
    }

    /**
     * Check if user has a given role
     *
     * @param role
     *
     * @returns {boolean}
     */
    hasRole(role) {
        let allRoles = this.user.roles;
        let length   = allRoles.length;

        for (let i = 0; i < length; i++) {
            if (allRoles[i].slug === role) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check if the user has a permission or permissions.
     *
     * @param permission
     * @param all
     *
     * @returns {boolean}
     */
    can(permission, all = false) {
        let permissions = this.getArrayFrom(permission);

        return all ? this.canAll(permissions) : this.canOne(permissions);
    }

    /**
     * Check if the user has at least one permission.
     *
     * @param permissions
     *
     * @returns {boolean}
     */
    canOne(permissions) {
        let length = permissions.length;

        for (let i = 0; i < length; i++) {
            if (this.hasPermission(permissions[i])) {
                return true;
            }
        }

        return length === 0;
    }

    /**
     * Check if the user has all permissions.
     *
     * @param permissions
     *
     * @returns {boolean}
     */
    canAll(permissions) {
        let length = permissions.length;

        for (let i = 0; i < length; i++) {
            if (!this.hasPermission(permissions[i])) {
                return false;
            }
        }

        return length === 0;
    }

    /**
     * Check if user has a given permission
     *
     * @param permission
     *
     * @returns {boolean}
     */
    hasPermission(permission) {
        let allPermissions = this.user.all_permissions;
        let length         = allPermissions.length;

        for (let i = 0; i < length; i++) {
            if (allPermissions[i].slug === permission) {
                return true;
            }
        }

        return false;
    }

    /**
     * Returns an argument as an array
     *
     * @param argument
     *
     * @returns {*[]}
     */
    getArrayFrom(argument) {
        return argument.constructor === Array ? argument : [argument];
    }

}
