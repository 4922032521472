import { inject }             from 'aurelia-framework';
import { I18N }               from 'aurelia-i18n';
import { promiseAlert, swal } from 'promise-alert';

@inject(I18N)
export class SweetAlert {

    /**
     * Constructor
     *
     * @param i18n
     */
    constructor(i18n) {
        this.i18n = i18n;
    }

    /**
     * Displays a success alert
     */
    successAlert(message = null, title = 'text.success') {
        message = message ? message : this.i18n.tr('form.message.success.action_succeeded');

        return promiseAlert(
            `${this.i18n.tr(title)}!`,
            message,
            'success',
        );
    }

    /**
     * Displays an error alert
     */
    warningAlert(message = null, title = 'text.attention') {
        message = message ? message : this.i18n.tr('form.message.failure.action-failed');

        return promiseAlert({
            title: `${this.i18n.tr(title)}!`,
            text:  message,
            type:  'warning',
        });
    }

    /**
     * Displays an error alert
     */
    errorAlert(message = null, title = 'text.error') {
        message = message ? message : this.i18n.tr('form.message.failure.action-failed');

        return promiseAlert(
            `${this.i18n.tr(title)}!`,
            message,
            'error',
        );
    }

    /**
     * Displays a confirm alert
     */
    confirmAlert(message, callback, title = 'text.attention') {
        let options = {
            title:              `${this.i18n.tr(title)}!`,
            text:               this.i18n.tr(message),
            type:               'warning',
            showCancelButton:   true,
            cancelButtonText:   this.i18n.tr('form.button.cancel'),
            confirmButtonColor: '#FF7043',
            confirmButtonText:  this.i18n.tr('form.button.ok'),
            closeOnConfirm:     true,
        };

        return promiseAlert(options, callback).then((confirmed) => {
            if (confirmed && callback instanceof Function) {
                return callback(confirmed);
            }

            return true;
        });
    }

    /**
     * Displays an error alert and appends the form validations returned by the server
     */
    errorWithFormValidationsAlert(response = null, title = 'text.error') {
        let message = response ? response.message : this.i18n.tr('form.message.failure.action-failed');
        let errors  = (response && response.errors) ? response.errors : null;

        return promiseAlert({
            title: `${this.i18n.tr(title)}!`,
            text:  message + '<ul>' + errors + '</ul>',
            type:  'error',
            html:  true,
        });
    }

    /**
     * Displays a response alert
     */
    responseAlert(response) {
        if (response.status === true) {
            return this.successAlert(response.message);
        }

        if (response.status === false) {
            return response.errors
                ? this.errorWithFormValidationsAlert(response)
                : this.errorAlert(response.message);
        }
    }

    /**
     * Displays a custom alert
     */
    customAlert(options, callback) {
        return promiseAlert(options, callback).then((confirmed) => {
            if (callback instanceof Function) {
                return callback(confirmed);
            }

            return true;
        });
    }

    /**
     * Immediately closes any open sweet alert
     *
     * @returns {*}
     */
    close() {
        return swal.close();
    }
}
