if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (obj, fromIndex) {

        if (fromIndex == null) {
            fromIndex = 0;
        } else if (fromIndex < 0) {
            fromIndex = Math.max(0, this.length + fromIndex);
        }

        for (var i = fromIndex, j = this.length; i < j; i++) {
            if (this[i] === obj) {
                return i;
            }
        }

        return -1;
    };
}

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        }
    });
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes#Polyfill
if (!String.prototype.includes) {
    String.prototype.includes = function (search, start) {
        'use strict';

        if (search instanceof RegExp) {
            throw TypeError('first argument must not be a RegExp');
        }

        if (start === undefined) {
            start = 0;
        }

        return this.indexOf(search, start) !== -1;
    };
}

window.UA_EDGE    = 'ua-edge';
window.UA_OPERA   = 'ua-opera';
window.UA_CHROME  = 'ua-chrome';
window.UA_SAFARI  = 'ua-safari';
window.UA_FIREFOX = 'ua-firefox';
window.UA_UNKNOWN = 'ua-unknown';

window.browserAgent = function () {
    var ua = (navigator.userAgent || '').toLowerCase();

    if (ua.indexOf('opr') >= 0) {
        return UA_OPERA;
    } else if (ua.indexOf('edge') >= 0) {
        return UA_EDGE;
    } else if (ua.indexOf('chrome') >= 0) {
        return UA_CHROME;
    } else if (ua.indexOf('safari') >= 0) {
        return UA_SAFARI;
    } else if (ua.indexOf('firefox') >= 0) {
        return UA_FIREFOX;
    }

    return UA_UNKNOWN;
};

window.isTrue = function (b) {
    return (/^(true|yes|1|y|on)$/i).test(b);
};

window.isFalse = function (b) {
    return (/^(false|no|0|n|off)$/i).test(b);
};

window.isEmpty = function (a) {
    if (typeof a === 'number') {
        return false;
    }

    if (typeof a === 'boolean') {
        return false;
    }

    return a === undefined || a === null || a === '' || a.length === 0 || Object.keys(a).length === 0;
};

window.isBoolean = function (b) {
    return (typeof b === 'boolean');
};

window.isString = function (a) {
    return (typeof a === 'string');
};

window.isNumber = function (a) {
    return (typeof a === 'number' && Number.isInteger(a));
};

window.isDecimal = function (a) {
    return (typeof a === 'number');
};

window.isNumeric = function (obj) {
    var realStringObj = obj && obj.toString();

    return typeof obj !== 'undefined' && obj !== null && !obj.isArray && (realStringObj - parseFloat(realStringObj) + 1) >= 0;
};

window.isFunction = function (a) {
    return (typeof a === 'function');
};

window.isArray = function (a) {
    return Array.isArray ? Array.isArray(a) : Object.prototype.toString.call(a) === '[object Array]';
};

window.isObject = function (obj) {
    return (!!obj) && (obj.constructor === Object);
};

window.inArray = function (array, item) {
    return (array.indexOf(item) !== -1);
};

window.getParentByTag = function (el, selector, last) {
    do {
        if (last && last instanceof Element && el === last) {
            return null;
        }

        if (last && (typeof last) === 'string' && (el.classList.contains(last) || el.tagName.toLowerCase() === last.toLowerCase())) {
            return null;
        }

        if (el.tagName.toLowerCase() === selector.toLowerCase()) {
            return el;
        }

        el = el.parentElement;
    } while (el !== null);

    return null;
};

window.getParentByClass = function (el, selector, last) {
    do {
        if (last && last instanceof Element && el === last) {
            return null;
        }

        if (last && (typeof last) === 'string' && (el.classList.contains(last) || el.tagName.toLowerCase() === last.toLowerCase())) {
            return null;
        }

        if (el.classList.contains(selector)) {
            return el;
        }

        el = el.parentElement;

    } while (el !== null);

    return null;
};

// TODO: replace single letters with something more intuitive
window.generateUuid = function () {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (a) {
        return (a ^ Math.random() * 16 >> a / 4).toString(16);
    });
};

String.prototype.interpolate = function (model) {
    return this.replace(/\${([^{}]*)}/g, function (a, b) {
        var r = model[b];

        return (typeof r === 'string' || typeof r === 'number') ? r : a;
    });
};

String.prototype.ascii = function () {
    var str = this.toString();

    if (isEmpty(str)) {
        return '';
    }

    var conversions = {};

    conversions['ae'] = 'ä|æ|ǽ';
    conversions['oe'] = 'ö|œ';
    conversions['ue'] = 'ü';
    conversions['Ae'] = 'Ä';
    conversions['Ue'] = 'Ü';
    conversions['Oe'] = 'Ö';
    conversions['A']  = 'À|Á|Â|Ã|Ä|Å|Ǻ|Ā|Ă|Ą|Ǎ';
    conversions['a']  = 'à|á|â|ã|å|ǻ|ā|ă|ą|ǎ|ª';
    conversions['C']  = 'Ç|Ć|Ĉ|Ċ|Č';
    conversions['c']  = 'ç|ć|ĉ|ċ|č';
    conversions['D']  = 'Ð|Ď|Đ';
    conversions['d']  = 'ð|ď|đ';
    conversions['E']  = 'È|É|Ê|Ë|Ē|Ĕ|Ė|Ę|Ě';
    conversions['e']  = 'è|é|ê|ë|ē|ĕ|ė|ę|ě';
    conversions['G']  = 'Ĝ|Ğ|Ġ|Ģ';
    conversions['g']  = 'ĝ|ğ|ġ|ģ';
    conversions['H']  = 'Ĥ|Ħ';
    conversions['h']  = 'ĥ|ħ';
    conversions['I']  = 'Ì|Í|Î|Ï|Ĩ|Ī|Ĭ|Ǐ|Į|İ';
    conversions['i']  = 'ì|í|î|ï|ĩ|ī|ĭ|ǐ|į|ı';
    conversions['J']  = 'Ĵ';
    conversions['j']  = 'ĵ';
    conversions['K']  = 'Ķ';
    conversions['k']  = 'ķ';
    conversions['L']  = 'Ĺ|Ļ|Ľ|Ŀ|Ł';
    conversions['l']  = 'ĺ|ļ|ľ|ŀ|ł';
    conversions['N']  = 'Ñ|Ń|Ņ|Ň';
    conversions['n']  = 'ñ|ń|ņ|ň|ŉ';
    conversions['O']  = 'Ò|Ó|Ô|Õ|Ō|Ŏ|Ǒ|Ő|Ơ|Ø|Ǿ';
    conversions['o']  = 'ò|ó|ô|õ|ō|ŏ|ǒ|ő|ơ|ø|ǿ|º';
    conversions['R']  = 'Ŕ|Ŗ|Ř';
    conversions['r']  = 'ŕ|ŗ|ř';
    conversions['S']  = 'Ś|Ŝ|Ş|Š';
    conversions['s']  = 'ś|ŝ|ş|š|ſ';
    conversions['T']  = 'Ţ|Ť|Ŧ';
    conversions['t']  = 'ţ|ť|ŧ';
    conversions['U']  = 'Ù|Ú|Û|Ũ|Ū|Ŭ|Ů|Ű|Ų|Ư|Ǔ|Ǖ|Ǘ|Ǚ|Ǜ';
    conversions['u']  = 'ù|ú|û|ũ|ū|ŭ|ů|ű|ų|ư|ǔ|ǖ|ǘ|ǚ|ǜ';
    conversions['Y']  = 'Ý|Ÿ|Ŷ';
    conversions['y']  = 'ý|ÿ|ŷ';
    conversions['W']  = 'Ŵ';
    conversions['w']  = 'ŵ';
    conversions['Z']  = 'Ź|Ż|Ž';
    conversions['z']  = 'ź|ż|ž';
    conversions['AE'] = 'Æ|Ǽ';
    conversions['ss'] = 'ß';
    conversions['IJ'] = 'Ĳ';
    conversions['ij'] = 'ĳ';
    conversions['OE'] = 'Œ';
    conversions['f']  = 'ƒ';

    for (var i in conversions) {
        var re = new RegExp(conversions[i], 'g');

        str = str.replace(re, i);
    }

    return str;
};
