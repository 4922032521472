import { inject } from 'aurelia-framework';
import { I18N }   from 'aurelia-i18n';
import { jGrowl } from 'jgrowl';

@inject(I18N)
export class Notifier {

    /**
     * Constructor
     *
     * @param i18n
     */
    constructor(i18n) {
        this.i18n = i18n;
    }

    /**
     * Raises a generic notice
     *
     * @param options
     */
    notice(options) {
        $.jGrowl(options.message, {
            header: options.header,
            theme:  options.theme,
        });
    }

    /**
     * Performs a generic notice
     *
     * @param message
     * @param options
     */
    defaultNotice(message, options = {}) {
        options.message = message;
        options.theme   = 'bg-primary alert-styled-left';

        this.notice(options);
    }

    /**
     * Performs a response notice
     *
     * @param response
     * @param options
     */
    responseNotice(response, options = {}) {
        return response.status
            ? this.successNotice(response.message, options)
            : this.dangerNotice(response.message, options);
    }

    /**
     * Performs a danger notice
     *
     * @param message
     * @param options
     */
    dangerNotice(message = 'text.error-message.action-failed', options = {}) {
        options.message = this.i18n.tr(message);
        options.theme   = 'bg-danger alert-styled-left';

        this.notice(options);
    }

    /**
     * Performs a success notice
     *
     * @param message
     * @param options
     */
    successNotice(message = 'text.success-message.action-succeeded', options = {}) {
        options.message = this.i18n.tr(message);
        options.theme   = 'bg-success alert-styled-left';

        this.notice(options);
    }

    /**
     * Performs a warning notice
     *
     * @param message
     * @param options
     */
    warningNotice(message, options = {}) {
        options.message = message;
        options.theme   = 'bg-warning alert-styled-left';

        this.notice(options);
    }

    /**
     * Performs a info notice
     *
     * @param message
     * @param options
     */
    infoNotice(message, options = {}) {
        options.message = message;
        options.theme   = 'bg-info alert-styled-left';

        this.notice(options);
    }

}
