export class PostCompleteStep {

    run(instruction, next) {
        if (! instruction.config.settings.noScroll) {
            $('html, body, .modal').animate({ scrollTop: 0 }, 100);
        }

        return next();
    }

}
